import styled from 'styled-components'

export const ComponentWrapper = styled.div<{
  priority: 'high' | 'low'
  margins: number[]
  iconWidth: number
}>`
  display: flex;
  gap: 20px;
  align-items: center;
  margin: ${({ margins }) => margins.map((margin) => `${margin}px`).join(' ')};

  & > :first-child {
    border-radius: 50%;
    border: 1px solid rgba(6, 198, 143, 1);
    width: ${({ iconWidth }) => iconWidth}px;
    height: ${({ iconWidth }) => iconWidth}px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ iconWidth }) => iconWidth / 2}px;
  }

  & > :last-child {
    color: ${({ priority }) =>
      priority === 'high' ? '#000' : 'rgba(99, 99, 105, 1)'};
    font-family: 'Poppins', sans-serif;
    font-weight: ${({ priority }) => (priority === 'high' ? 600 : 400)};
    line-height: 24px;
    font-size: 16px;
  }
`
export const ExtendedComponentWrapper = styled.div<{
  doubleArrow?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > :first-child {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: ${({ doubleArrow }) => (doubleArrow ? '0' : '-25px')};
    p {
      margin-top: ${({ doubleArrow }) => (doubleArrow ? '0' : '-6px')};
      font-family: 'Poppins-SemiBold', sans-serif;
    }
  }

  & > :last-child {
    font-family: 'Poppins', sans-serif;
    line-height: 24px;
    color: #636369;
  }
`
