import React from 'react'
import { ExtendedComponentWrapper } from './styled-components'

import ExtendedArrowSvg from 'images/svg/innerArrowExtended.svg'
import DoubleArrowSvg from 'images/svg/doubleArrow.svg'

interface ComponentProps {
  title: string
  description: string
  doubleArrow?: boolean
}
const InnerArticleExtended = ({
  title,
  description,
  doubleArrow,
}: ComponentProps) => {
  return (
    <ExtendedComponentWrapper doubleArrow={doubleArrow}>
      <div>
        {doubleArrow ? <DoubleArrowSvg /> : <ExtendedArrowSvg />}
        <p>{title}</p>
      </div>
      <p>{description}</p>
    </ExtendedComponentWrapper>
  )
}

export default InnerArticleExtended
