import React from 'react'
import { StyledButton } from './styled-components'
import { Link } from 'gatsby'

interface ComponentProps {
  children: React.ReactNode
  color?: string
  onClick?: () => void
  to?: string
  id?: string
  link?: string
  type?: 'button' | 'submit' | 'reset'
  paddings?: number
  className?: string
}

const Button = ({
  children,
  color = '#8636CC',
  to,
  link,
  id,
  onClick,
  paddings = 30,
  type,
  className,
}: ComponentProps) => {
  const Content = () => {
    return (
      <StyledButton
        className={`custom-button ${className}`}
        color={color}
        onClick={onClick}
        padding={paddings}
        id={id}
        type={type}
        aria-label={'Schedule a meeting'}
      >
        {children}
      </StyledButton>
    )
  }

  return to ? (
    <a
      href={to}
      target="_blank"
      className="link"
      style={{ display: 'inline-block' }}
      aria-label={'Schedule a meeting'}
    >
      <Content />
    </a>
  ) : link ? (
    <Link
      to={link}
      className="link"
      aria-label={'Schedule a meeting'}
    >
      <Content />
    </Link>
  ) : (
    <Content />
  )
}

export default Button
