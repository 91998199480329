import styled from 'styled-components'

export const Wrapper = styled.div`
  background: rgba(230, 249, 244, 0.4);
  border: 1px solid #06c68f;
  border-radius: 10px;
  padding: 32px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    border-radius: 50%;
    .gatsby-image-wrapper {
      height: 200px;
      width: 200px;
      border-radius: 50%;
      img {
        border-radius: 50%;
      }
    }
  }
`

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
  margin-bottom: 18px;
  text-align: center;

  h4 {
    font-weight: 700;
    font-size: 16px;
    font-family: 'Montserrat-bold', sans-serif;
  }

  p {
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #4b4b4b;
  }
`

export const Content = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #4c4c4c;
`
