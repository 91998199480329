import React, { HTMLInputTypeAttribute } from 'react'
import {
  CheckBoxContainer,
  Wrapper,
} from './styled-components'

interface InputProps {
  children?: React.ReactNode
  label?: string
  placeholder?: string
  type: HTMLInputTypeAttribute
  id?: string
  required?: boolean
}

const StyledInput = ({
  children,
  label,
  placeholder,
  type = 'text',
  id,
  required,
}: InputProps) => {
  if (type === 'checkbox') {
    return (
      <CheckBoxContainer>
        <p>{label}</p>
        <div>{children}</div>
      </CheckBoxContainer>
    )
  }
  return (
    <Wrapper>
      <label htmlFor={id}>{label}</label>
      {type !== 'textarea' ? (
        <input
          required={required}
          id={id}
          name={id}
          placeholder={placeholder}
          type={type}
          min={0}
        />
      ) : (
        <textarea
          placeholder={placeholder}
          name={id}
          id={id}
          rows={5}
          required={required}
        ></textarea>
      )}
    </Wrapper>
  )
}

export default StyledInput
