import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { Content, Heading, Wrapper } from './styled-components'

const TeamMember = ({
  profilePic,
  name,
  designation,
  description,
}: {
  profilePic: IGatsbyImageData
  name: string
  designation: string
  description: string
}) => {
  return (
    <Wrapper>
      <div className="image">
        {' '}
        <GatsbyImage
          className="profilePic"
          image={profilePic}
          alt="profilePic"
        />
      </div>
      <Heading>
        <h4>{name}</h4>
        <p>{designation}</p>
      </Heading>
      <Content>{description}</Content>
    </Wrapper>
  )
}

export default TeamMember
