export enum currentIconEnums {
  flag = 'flag',
  share = 'share',
  videoCam = 'videoCam',
  waves = 'waves',
  wavesReverse = 'wavesReverse',
  wavesReversePurple = 'wavesReversePurple',
  TwoXTwo = 'TwoXTwo',
  ThreeXThree = 'ThreeXThree',
  ThreeXThreePurple = 'ThreeXThreePurple',
  pill = 'pill',
  pillPurple = 'pillPurple',
  rocket = 'rocket',
  rocketWindow = 'rocketWindow',
  tournamentCup = 'tournamentCup',
  certificate = 'certificate',
  crossCircles = 'crossCircles',
  moreInformation = 'moreInformation',
  moreOptions = 'moreOptions',
  moreSkepticism = 'moreSkepticism',
  moreDissatisfaction = 'moreDissatisfaction',
}

export interface ArticleItemProps {
  icon?: currentIconEnums | number
  numberIcon?: number
  gap?: number | number[]
  themeColor?: string
  title: string
  titleColor?: string
  description: string
  width?: string
  aligment?: 'left' | 'center' | 'right'
}
