import React, { ReactNode } from 'react'

import CheckedSvg from 'images/svg/checked.svg'
import styled from 'styled-components'

const Checked = ({ children }: { children: ReactNode }) => {
  return (
    <Wrapper>
      <div>
        <CheckedSvg className={'CheckedSvg'} />
      </div>
      <p>{children}</p>
    </Wrapper>
  )
}

export default Checked

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  p {
    font-family: 'Poppins';
    color: #636369;
    line-height: 24px;
  }
`
