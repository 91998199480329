import {
  faCircleCheck,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TPFiveStar from 'images/svg/FiveStar.svg'
import {
  GatsbyImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { StyledText } from '..'
import {
  CloseButton,
  Container,
  ReviewContainer,
  TrustPilotContainer,
  UserDataContaienr,
  Wrapper,
} from './styled-components'

const Variants = {
  open: {
    zIndex: 99999,
    backgroundColor: '#fff',
    height: '100vh',
    opacity: 1,
    transition: {
      opacity: { stiffness: 1000, duration: 0.2 },
      height: { duration: 0 },
      delay: 0.2,
    },
  },
  closed: {
    zIndex: -1,
    opacity: 0,
    height: 0,
    backgroundColor: '#transparent',
    transition: {
      opacity: { stiffness: 1000, duration: 0.2 },
      x: { duration: 0 },
      height: { duration: 0 },
      delay: 0.3,
      duration: 0.2,
    },
  },
}

const UserDataVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000 },
      delay: 0.4,
    },
  },
  closed: {
    x: -20,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
}

const ReviewVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000 },
      delay: 0.4,
    },
  },
  closed: {
    x: 20,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
}

const ModalTestimonial = ({
  fullName,
  designation,
  review,
  profilePicture,
  companyPic,
  onClose,
  embeddedVideo,
}: {
  fullName: string
  designation: string
  review: {
    review: string
  }
  profilePicture: {
    gatsbyImage: IGatsbyImageData
  }
  companyPic?: IGatsbyImageData
  embeddedVideo?: {
    childMarkdownRemark: {
      html: string
    }
  }
  onClose: () => void
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    review ? setOpen(true) : setOpen(false)
  }, [review])

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      onClose()
    }, 300)
  }

  const windowTemp =
    typeof window !== 'undefined' && (window as any)
  const isDesignation =
    designation !== '!' && designation !== ''
  return (
    <Wrapper
      topRef={windowTemp.scrollY}
      animate={open ? 'open' : 'closed'}
      variants={Variants}
    >
      <Container>
        <UserDataContaienr
          animate={open ? 'open' : 'closed'}
          variants={UserDataVariants}
        >
          {profilePicture && (
            <GatsbyImage
              image={profilePicture.gatsbyImage}
              alt="profile-pic"
              className="profile-pic"
            />
          )}
          <h6>{fullName}</h6>
          {isDesignation && <p>{designation}</p>}
          {/* <TrustPilotContainer>
            <StyledText className="rating-text">
              5 Star Rating on TrustPilot{' '}
              <FontAwesomeIcon icon={faCircleCheck} />
            </StyledText>
            <TPFiveStar width="120px" height="28px" />
          </TrustPilotContainer> */}
          {/* {companyPic && (
            <Img
              className="companyImage"
              fluid={companyPic}
              alt="profile-pic"
            />
          )} */}
        </UserDataContaienr>
        <ReviewContainer
          animate={open ? 'open' : 'closed'}
          variants={ReviewVariants}
        >
          {embeddedVideo && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  embeddedVideo.childMarkdownRemark.html,
              }}
            />
          )}
          <p>"{review?.review}"</p>
        </ReviewContainer>
        <CloseButton onClick={handleClose} visible={open}>
          <FontAwesomeIcon icon={faX} />
        </CloseButton>
      </Container>
    </Wrapper>
  )
}

export default ModalTestimonial
