import { motion } from 'framer-motion'
import styled from 'styled-components'

export const WideScreenContainer = styled.section`
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    display: none;
  }
`
export const MobileContainer = styled.section`
  .mobile-title {
    padding-top: 24px;
    padding-bottom: 8px;
    text-align: center;
  }
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    display: none;
  }
`

export const Wrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding: 100px 165px 30px 165px;
  ${({ theme }) => theme.placeholders.maxWidth}

  .full-width {
    grid-column: 1 / span 3;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 100px 100px 30px 100px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 80px 50px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
`

export const SyllabusContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  padding: 40px 165px;
  align-items: center;
  gap: 30px;
  transition: all 0.3s ease-in-out;
  ${({ theme }) => theme.placeholders.maxWidth}
  @media (max-width: ${({ theme }) =>
    theme.breakpoints.wl}) {
    padding-inline: 100px !important;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding-inline: 50px !important;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    flex-direction: column;
  }
`

export const ImageContainer = styled.div`
  width: 35%;
  height: 100%;
  border-radius: 14px;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 14px;

    img {
      border-radius: 14px;
    }
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    width: 100%;
  }
`

export const ContentContainer = styled.div`
  width: 60%;

  h2 {
    font-family: 'Poppins-SemiBold';
    font-size: 2rem;
    color: #06c68f;
  }

  .label {
    font-family: 'Montserrat';
    font-size: 0.9rem;
    color: #636369;
    margin-bottom: 20px;
  }

  .description {
    font-family: 'Poppins';
    font-size: 1.1rem;
    white-space: pre-line;
    color: #636369;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    width: 100%;
  }
`

export const moduleVariants = {
  hidden: {
    opacity: 0,
    y: -20,
    height: 0,
    padding: '0 165px ',
    spring: { stiffness: 100 },
  },
  visible: {
    opacity: 1,
    y: 0,
    padding: '40px 165px',
    height: '480px',
    spring: { stiffness: 100, damping: 100 },
    transition: {
      height: { duration: 0.3 },
    },
  },
}
