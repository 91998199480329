import styled from 'styled-components'

export const FlipCard = styled.div`
  background-color: transparent;
  width: 333px;
  height: 333px;
  perspective: 1000px;

  &:focus {
    outline: 0;

    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }

  &:hover {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
`

export const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-style: preserve-3d;
  border-radius: 14px;
`

export const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: rgb(251, 251, 251);
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  border-radius: 14px;
  padding: 25px;

  h6 {
    font-family: 'Poppins-SemiBold';
    color: #06c68f;
    text-align: center;
    font-size: 16px;
  }
`

export const FlipCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: rgb(251, 251, 251);
  color: white;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  padding: 25px;
  h6 {
    font-family: 'Poppins-SemiBold';
    color: #06c68f;
    font-size: 18px;
    text-align: center;
  }

  p {
    font-family: 'Poppins';
    color: #636369;
    font-size: 16px;
    text-align: center;
  }
`
