import styled from 'styled-components'

export const Wrapper = styled.div<{ expanded: boolean }>`
  padding: 24px;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #fdfdfd;

  .question {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    user-select: none;

    .module,
    p {
      font-size: 18px;
      font-family: 'Poppins';
      user-select: none;
      font-weight: bold;
      display: flex;
      align-items: flex-start;
      gap: 20px;

      @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
        font-size: 15px;
      }

      .index {
        border-radius: 50%;
        color: #06c68f;
        font-size: 28px;
        font-family: 'Poppins-SemiBold';
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        @media (max-width: 768px) {
          border: 0;
          padding-top: 0;
        }
      }

      .label {
        flex-direction: column;
        gap: 10px;
        .description {
          font-size: 14px;
          color: #636369;
        }
      }
    }
  }

  .answer {
    overflow: hidden;
    padding: ${({ expanded }) =>
      expanded ? '14px !important' : '0 14px !important'};
    padding-bottom: 0;
    color: #636369;
    font-family: 'Poppins';
    line-height: 24px;
    transition: all 0.5s ease-in-out;
    max-height: ${({ expanded }) => (expanded ? '1900px' : '0')};
    @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
      font-size: 14px;
    }

    .image {
      margin-top: 20px;
    }

    .label {
      margin-block: 20px;
    }
  }
`
