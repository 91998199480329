import React, { useState } from 'react'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Wrapper } from './styled-components'

interface ComponentProps {
  question: string
  answer: string
}

const Expandable = ({ question, answer }: ComponentProps) => {
  const [expanded, setExpanded] = useState(false)

  // expands the answer
  const handleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Wrapper expanded={expanded} onClick={handleExpand}>
      <div className="question">
        <p>{question}</p>
        <FontAwesomeIcon
          icon={faAngleDown}
          style={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
            transition: '0.5s',
          }}
        />
      </div>
      <div className="answer">{answer}</div>
    </Wrapper>
  )
}

export default Expandable
