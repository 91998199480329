import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { returnLineBreaks } from 'helpers/returnLineBreaks'
import React from 'react'
import styled from 'styled-components'

interface TitleProps {
  children: React.ReactNode
  fontSize?: string
  fontType?: 'regular' | 'bold' | 'semibold'
  fontWeight?: number
  icon?: IconProp
  iconAlign?: 'left' | 'right'
  textAlign?: 'left' | 'center'
  textColor?: string
  className?: string
  variant?: 'dark' | 'light'
  lineHeight?: string
}

const Title = ({
  children,
  fontSize = '32px',
  fontType = 'bold',
  fontWeight = 700,
  textAlign = 'left',
  textColor = '#000000',
  lineHeight,
  variant,
  className,
  icon,
  iconAlign = 'left',
}: TitleProps) => {
  const Content = () => {
    if (typeof children === 'string') {
      return returnLineBreaks(children)
    }
    return <>{children}</>
  }
  return (
    <StyledTitle
      fontSize={fontSize}
      fontType={fontType}
      fontWeight={fontWeight}
      textAlign={textAlign}
      textColor={textColor}
      className={`title ${className}`}
      variant={variant}
      lineHeight={lineHeight}
      icon={icon}
    >
      {icon && iconAlign === 'left' && (
        <FontAwesomeIcon icon={icon} />
      )}
      {Content()}
      {icon && iconAlign === 'right' && (
        <FontAwesomeIcon icon={icon} />
      )}
    </StyledTitle>
  )
}

export default Title

const StyledTitle = styled.p<TitleProps>`
  ${({ icon }) =>
    icon &&
    `
  display: flex !important;
  gap: 10px;
  align-items: center;
  `}
  margin-bottom: 10px;
  white-space: break-spaces;
  font-size: ${({ fontSize, variant }) => {
    if (variant) {
      return variant === 'dark' ? '30px' : '16px'
    }
    if (fontSize) {
      return fontSize
    }
  }};
  font-family: ${({ fontType, variant }) => {
    if (variant) {
      return variant === 'light'
        ? 'Poppins'
        : 'Poppins-Bold'
    }
    if (fontType) {
      // return fontType === 'regular' ? 'Poppins' : 'Poppins-bold'
      switch (fontType) {
        case 'regular':
          return 'Poppins'
        case 'bold':
          return 'Poppins-Bold'
        case 'semibold':
          return 'Poppins-SemiBold'
      }
    }
  }};
  font-weight: ${({ fontWeight, variant }) => {
    if (variant) {
      return variant === 'light' ? '400' : '700'
    }
    if (fontWeight) {
      return fontWeight
    }
  }};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ textColor, variant }) => {
    if (variant) {
      return variant === 'light'
        ? 'rgba(99, 99, 105, 1)'
        : '#FFFFFF'
    }
    if (textColor) {
      return textColor
    }
  }};

  line-height: ${({ lineHeight }) => {
    if (lineHeight) {
      return lineHeight
    }
  }};

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.wxs}) {
    font-size: ${({ fontSize, variant }) => {
      if (variant) {
        return variant === 'dark' ? '32px' : '20px'
      }
      if (fontSize) {
        if (parseInt(fontSize) > 32) {
          return parseInt(fontSize) * 0.5 + 'px'
        }
        if (parseInt(fontSize) > 20) {
          return parseInt(fontSize) * 0.6 + 'px'
        }
        if (parseInt(fontSize) > 16) {
          return parseInt(fontSize) * 0.7 + 'px'
        }
      }
      return fontSize
    }}};
  }
`
