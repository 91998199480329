import React from 'react'
import {
  FlipCard,
  FlipCardBack,
  FlipCardFront,
  FlipCardInner,
} from './styled-components'

import MoreInformationSvg from 'images/svg/more-information.svg'
import MoreOptionsSvg from 'images/svg/more-options.svg'
import MoreSkepticismSvg from 'images/svg/more-skepticism.svg'
import MoreDissatisfactionSvg from 'images/svg/dislike.svg'

const Flip = ({
  frontTitle,
  frontSvg,
  backTitle,
  backContent,
}: {
  frontTitle: string
  frontSvg?: string
  backTitle: string
  backContent: string
}) => {
  const getCurrentSvg = (Svg: string) => {
    switch (Svg) {
      case 'information':
        return <MoreInformationSvg />
      case 'options':
        return <MoreOptionsSvg />
      case 'skepticism':
        return <MoreSkepticismSvg />
      case 'dislike':
        return <MoreDissatisfactionSvg />
      default:
        return null
    }
  }

  return (
    <FlipCard className="flip-card" tabIndex={0}>
      <FlipCardInner className="flip-card-inner">
        <FlipCardFront className="flip-card-front">
          {frontSvg && (
            <div className="svg-container">
              {getCurrentSvg(frontSvg)}
            </div>
          )}
          <h6>{frontTitle}</h6>
        </FlipCardFront>
        <FlipCardBack className="flip-card-back">
          <h6>{backTitle}</h6>
          <p>{backContent}</p>
        </FlipCardBack>
      </FlipCardInner>
    </FlipCard>
  )
}

export default Flip
