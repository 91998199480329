import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Wrapper = styled(motion.div)<{
  topRef: number
  visible?: string
}>`
  top: ${({ topRef }) => topRef}px;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
export const TrustPilotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ratting-text {
    color: #6c6c85;
  }
`
export const Container = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  gap: 10%;
  height: 100%;
  width: 100vw;
  padding-block: 3%;
  align-items: center;
  padding-inline: 10%;
  max-width: 1600px;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    flex-direction: column;
    gap: 20px;
    padding-inline: 20px;
    justify-content: flex-start;
    padding-block: 5%;
  }
`

export const UserDataContaienr = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flxe-start;
  align-items: center;
  width: 30%;
  gap: 10px;

  .gatsby-image-wrapper {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .gatsby-image-wrapper img {
    border-radius: 50%;
  }

  .profile-pic {
    border-radius: 50%;
    img,
    div {
      border-radius: 50%;
    }
  }

  .companyImage {
    width: 20%;
    height: auto;
    aspect-ratio: unset;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    width: 100%;
    align-items: center;
    margin-top: 20px;

    .gatsby-image-wrapper {
      width: 40%;
    }

    .companyImage {
      width: 15%;
    }
  }
  h6 {
    font-size: 2rem;
    font-family: 'Poppins-Bold', sans-serif;
    color: #414042;
    text-align: center;
  }
  p {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    color: #414042;
  }
`

export const ReviewContainer = styled(motion.div)`
  width: 60%;
  font-size: 1.3rem;
  font-family: 'Poppins-Light', sans-serif;
  white-space: pre-line;
  max-height: 100%;
  overflow-y: auto !important;

  iframe {
    margin-bottom: 40px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    width: 100%;
  }
`

export const CloseButton = styled(motion.div)<{
  visible: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  right: 40px;
  cursor: pointer;
  position: absolute;
  font-size: 1.25rem;
  top: 30px;
  background-color: transparent;
  border: none;
  outline: none;
  transition: transform 0.2s
    cubic-bezier(0.175, 0.885, 0.32, 1.275);
  visibility: ${({ visible }) =>
    visible ? 'visible' : 'hidden'};
  &:hover {
    transform: scale(1.1);
  }
`
