import React, { ReactNode } from 'react'
import { SectionWrapper } from './styled-components'

import LightGreenBottomWave from 'images/backgrounds/lightGreenWave.svg'
import LightGreyBottomWave from 'images/backgrounds/lightGreyWave.svg'
import PinkTopWave from 'images/backgrounds/pinkTopWave.svg'
import PinkBottomWave from 'images/backgrounds/pinkBottomWave.svg'

interface WaveWrapperProps {
  children: ReactNode
  color:
    | 'pink'
    | 'lightGrey'
    | 'lightGreen'
    | 'bottomPink'
    | 'lightGreen-reversed'
    | 'lightGrey-reversed'
    | 'lightGrey-sandwitch'
}

const Wrapper = ({ children, color = 'pink' }: WaveWrapperProps) => {
  switch (color) {
    case 'pink':
      return (
        <SectionWrapper color={color}>
          <div className="topWave">
            <PinkTopWave />
          </div>
          <div className="content">{children}</div>
          <div className="bottomWave">
            <PinkBottomWave />
          </div>
        </SectionWrapper>
      )
    case 'bottomPink':
      return (
        <SectionWrapper color='bottomPink'>
          <div className="content">{children}</div>
          <div className="bottomWave">
            <PinkBottomWave />
          </div>
        </SectionWrapper>
      )
    case 'lightGrey':
      return (
        <SectionWrapper color={color}>
          <div className="content">{children}</div>
          <div className="bottomWave">
            <LightGreyBottomWave />
          </div>
        </SectionWrapper>
      )
    case 'lightGrey-reversed':
      return (
        <SectionWrapper color={'lightGrey'}>
          <div className="content">{children}</div>
          <div className="bottomWave">
            <LightGreyBottomWave style={{ transform: 'rotateY(180deg)' }} />
          </div>
        </SectionWrapper>
      )
    case 'lightGrey-sandwitch':
      return (
        <SectionWrapper color={'lightGrey'} withPadding={true}>
          <div className="bottomWave">
            <LightGreyBottomWave
              style={{
                transform: 'rotateY(180deg) rotateX(180deg)',
                marginBottom: '-10px',
              }}
            />
          </div>
          <div className="content">{children}</div>
          <div className="bottomWave">
            <LightGreyBottomWave style={{ transform: 'rotateY(180deg)' }} />
          </div>
        </SectionWrapper>
      )

    case 'lightGreen':
      return (
        <SectionWrapper color={color}>
          <div className="content">{children}</div>
          <div className="bottomWave">
            <LightGreenBottomWave />
          </div>
        </SectionWrapper>
      )
    case 'lightGreen-reversed':
      return (
        <SectionWrapper color={'lightGreen'}>
          <div className="content">{children}</div>
          <div className="bottomWave">
            <LightGreenBottomWave style={{ transform: 'rotateY(180deg)' }} />
          </div>
        </SectionWrapper>
      )
  }
}

export default Wrapper
