import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'
import React from 'react'
import styled from 'styled-components'
import { StyledButton, SUADA } from '..'

const CustomDialog = ({
  isOpen,
  children,
  onDismiss,
}: {
  isOpen: boolean
  onDismiss: () => void
  children?: React.ReactNode
}) => (
  <StyledDialog isOpen={isOpen} onDismiss={onDismiss}>
    <SUADA />
    {children || (
      <>
        We aim to have a member of our team get back to you
        within 48 hours, to the email address that you have
        provided.
        <br /> Have a great day!
      </>
    )}
    <StyledButton onClick={onDismiss}>Close</StyledButton>
  </StyledDialog>
)

export default CustomDialog

const StyledDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 50% !important;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  text-align: center;
  padding: 2% !important;
  max-width: 900px !important;
  border-radius: 10px;
  top: 50%;
  margin: 30vh auto !important;

  span {
    font-size: 36px;
    font-family: 'Poppins-Bold', sans-serif;
  }

  .custom-button {
    margin-top: 10px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    width: 90vw !important;
    margin: 30vh auto !important;
    padding: 6% 4% !important;

    font-size: 20px;

    span {
      font-size: 27px;
    }
  }
`
