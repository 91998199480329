import styled from 'styled-components'

export const Wrapper = styled.article`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    gap: 0px;
    align-items: flex-start;
  }
`

export const Content = styled.div`
  h6 {
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
  }
  p {
    font-family: 'Poppins';
    color: #636369;
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
  }
`

export const Index = styled.div<{ active: boolean }>`
  padding: 15px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #06c68f;
  font-size: 24px;
  font-family: 'Poppins-SemiBold';
  border: 1px solid #06c68f;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  @media (min-width: 768px) {
    &:hover {
      background-color: #06c68f;
      color: #fff;
    }
  }

  ${({ active }) =>
    active &&
    `
    @media (min-width: 768px) {
      background-color: #06c68f;
      color: #fff;
    }
  `}

  @media (max-width: 768px) {
    border: 0;
    padding-top: 0;
  }
`
