import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 30px 20px 20px 20px;
  border: 2px solid #06c68f;
  position: relative;

  p {
    font-size: 16px;
    line-height: 24px;
    color: #636369;
    font-family: 'Poppins', sans-serif;

    span {
      color: black;
    }
  }

  .check-container {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    left: 5%;
  }
`
