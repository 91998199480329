import React from 'react'
import styled from 'styled-components'

const SUADA = () => {
  return <Suada>SUADA</Suada>
}

export default SUADA

const Suada = styled.span`
  color: rgba(6, 198, 143, 1);
`
