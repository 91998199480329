import styled from 'styled-components'

export const StyledButton = styled.button<{ color: string; padding: number }>`
  background: ${(props) => props.color};
  border: 3px solid transparent;
  border: ${(props) => {
    switch (props.color) {
      case 'black':
        return '3px solid white'
      case 'white':
        return '3px solid #06C68F'
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: ${({ color }) => (color == 'white' ? '#06C68F' : '#fff ')};
  padding: ${({ color }) => (color === 'white' ? '18px' : '12px')}
    ${({ padding }) => padding}px;
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: ${({ color }) => (color === 'white' ? '22px' : '14px')};
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background: ${({ color }) => (color === 'white' ? '#06C68F' : '#fff')};
    color: ${({ color }) => color};
    border: 3px solid ${({ color }) => color};

    svg path {
      fill: ${({ color }) => color};
    }
  }

  svg path {
    transition: fill 0.2s ease-in-out;
  }
`
