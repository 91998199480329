import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface PartnerLogoProps {
  image: IGatsbyImageData
  alt: string
  placeholder?: 'none' | 'tracedSVG' | 'dominantColor' | 'blurred' | undefined
  backgroundColor?: string
  width?: string
}

const PartnerLogo = ({
  image,
  alt,
  backgroundColor,
  width,
}: PartnerLogoProps) => {
  const gatsbyImageData = getImage(image)
  return gatsbyImageData ? (
    <ImageContainer backgroundColor={backgroundColor} width={width}>
      <GatsbyImage image={gatsbyImageData} alt={alt} />
    </ImageContainer>
  ) : null
}

export default PartnerLogo

const ImageContainer = styled.div<{ backgroundColor?: string; width?: string }>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  ${({ width }) =>
    width !== undefined
      ? `
  width: ${width};
  .gatsby-image-wrapper {
    height: 100%;
  }
  `
      : `
  width: 15%; 
  .gatsby-image-wrapper {
    width: 100%;

    img {
      width: 100%;
    }
  }`}
  padding: 15px;
  opacity: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    width: 100%;

    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain !important;
      }
    }
  }
`
