import styled from 'styled-components'

export const SectionWrapper = styled.section<{
  color: 'pink' | 'lightGrey' | 'lightGreen' | 'bottomPink'
  withPadding?: boolean
}>`
  .topWave {
    margin-bottom: -4px;
  }
  .content {
    background-color: ${({ color }) => {
      switch (color) {
        case 'pink':
        case 'bottomPink':
          return 'rgba(207, 175, 235, 0.1)'
        case 'lightGrey':
          return 'rgba(252, 252, 252, 1)'
        case 'lightGreen':
          return 'rgba(6, 198, 143, 0.04)'
      }
    }};
    position: relative;

    & > .banner-sparkle {
      width: 48px;
      height: 56px;
      position: absolute;
    }

    & > .sparkle-1 {
      left: 3%;
      bottom: 37%;
    }

    & > .sparkle-2 {
      left: 18%;
      top: 22%;
    }

    & > .sparkle-3 {
      left: 35%;
      bottom: 18%;
    }

    & > .sparkle-4 {
      right: 35%;
      top: 22%;
    }

    & > .sparkle-5 {
      right: 10%;
      top: 32%;
    }

    @media (max-width: 1100px) {
      .banner-sparkle {
        display: none;
      }
    }
  }

  .topWave .gatsby-image-wrapper,
  .bottomWave .gatsby-image-wrapper {
    width: 100%;
    user-select: none;

    & div {
      max-width: unset !important;
    }
  }
`
