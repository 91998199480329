import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 33px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  trsnsform: scale(1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  .left-container {
    width: 80%;
    h6 {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 8px;
      font-family: 'Poppins-SemiBold';
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #636369;
      font-family: 'Poppins';
    }
  }

  .right-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 10%;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
    .right-container {
      transform: translateX(20%);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 20px;
  }
`
