import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ComponentWrapper } from './styled-components'

interface ComponentProps {
  children: React.ReactNode
  priority?: 'high' | 'low'
  margins?: number[]
  iconWidth?: number
}

const InnerArticle = ({
  children,
  priority = 'high',
  margins = [16, 0],
  iconWidth = 30,
}: ComponentProps) => {
  return (
    <ComponentWrapper
      priority={priority}
      margins={margins}
      iconWidth={iconWidth}
    >
      <div className="left-container">
        <FontAwesomeIcon icon={faArrowRight} color={'rgba(6, 198, 143, 1)'} />
      </div>
      <div className="content">{children}</div>
    </ComponentWrapper>
  )
}

export default InnerArticle
