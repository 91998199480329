import React from 'react'
import { Wrapper } from './styled-components'
import CheckSvg from 'images/svg/check.svg'

const Framework = ({
  label,
  description,
}: {
  label: string
  description: string
}) => {
  return (
    <Wrapper>
      <p>
        <span>{label}: </span>
        {description}
      </p>
      <div className="check-container">
        <CheckSvg />
      </div>
    </Wrapper>
  )
}

export default Framework
