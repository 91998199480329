import React, { useState } from 'react'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Wrapper } from './styled-components'
import {
  ContentContainer,
  ImageContainer,
  SyllabusContainer,
} from 'sections/SalesMastery/Modules/styled-components'
import {
  GatsbyImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'

interface ComponentProps {
  moduleFullData: {
    imageData: IGatsbyImageData
    heading: string
    label: string
    description: string
  }
  module: { label: string; description: string }
  index: number
}

const ExpandableModule = ({
  moduleFullData,
  module,
  index,
}: ComponentProps) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Wrapper expanded={expanded} onClick={handleExpand}>
      <div className="question">
        <div className="module">
          <span className="index">{index}</span>
          <p className="label">
            {module.label}
            <span className="description">
              {module.description}
            </span>
          </p>
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          style={{
            transform: expanded
              ? 'rotate(180deg)'
              : 'rotate(0)',
            transition: '0.5s',
          }}
        />
      </div>
      <SyllabusContainer className="answer ">
        <ImageContainer className="image">
          <GatsbyImage
            alt="module-image"
            image={moduleFullData.imageData}
          />
        </ImageContainer>
        <ContentContainer className="content">
          <h2>{moduleFullData.heading}</h2>
          <p className="label">{moduleFullData.label}</p>
          <p className="description">
            {moduleFullData.description}
          </p>
        </ContentContainer>
      </SyllabusContainer>
    </Wrapper>
  )
}

export default ExpandableModule
