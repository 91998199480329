import React from 'react'
import { ArticleText, IconContainer, Wrapper } from './styled-components'

import RocketIcon from 'images/svg/rocket.svg'
import RocketWindowIcon from 'images/svg/rocketWindow.svg'
import TournamentCupIcon from 'images/svg/tournamentCup.svg'
import CrossCirclesIcon from 'images/svg/crossCircles.svg'
import CertificateIcon from 'images/svg/certificateIcon.svg'
import FlagIcon from 'images/svg/flag.svg'
import VideoCamIcon from 'images/svg/videoCam.svg'
import WavesIcon from 'images/svg/waves.svg'
import WavesReverseIcon from 'images/svg/wavesReverse.svg'
import WavesReversePurpleIcon from 'images/svg/wavesReversePurple.svg'
import TwoXTwoIcon from 'images/svg/TwoXTwo.svg'
import ThreeXThreeIcon from 'images/svg/ThreeXThree.svg'
import ThreeXThreePurpleIcon from 'images/svg/ThreeXThreePurple.svg'
import PillIcon from 'images/svg/pill.svg'
import PillPurpleIcon from 'images/svg/pillPurple.svg'
import ShareIcon from 'images/svg/share.svg'

import {
  ArticleItemProps,
  currentIconEnums,
} from 'interfaces/FlexArticleInterfaces'

const FlexArticleItem = ({
  icon = currentIconEnums.flag,
  themeColor = '#06C68F',
  width = '30%',
  title,
  titleColor,
  description,
  gap,
  aligment = 'left',
}: ArticleItemProps) => {
  const CurrentIcon = ({ icon }: { icon: currentIconEnums | number }) => {
    switch (icon) {
      case currentIconEnums.flag:
        return <FlagIcon />
      case currentIconEnums.share:
        return <ShareIcon />
      case currentIconEnums.videoCam:
        return <VideoCamIcon />
      case currentIconEnums.waves:
        return <WavesIcon />
      case currentIconEnums.wavesReverse:
        return <WavesReverseIcon />
      case currentIconEnums.wavesReversePurple:
        return <WavesReversePurpleIcon />
      case currentIconEnums.TwoXTwo:
        return <TwoXTwoIcon />
      case currentIconEnums.ThreeXThree:
        return <ThreeXThreeIcon />
      case currentIconEnums.ThreeXThreePurple:
        return <ThreeXThreePurpleIcon />
      case currentIconEnums.pill:
        return <PillIcon />
      case currentIconEnums.pillPurple:
        return <PillPurpleIcon />
      case currentIconEnums.rocket:
        return <RocketIcon />
      case currentIconEnums.rocketWindow:
        return <RocketWindowIcon />
      case currentIconEnums.tournamentCup:
        return <TournamentCupIcon />
      case currentIconEnums.certificate:
        return <CertificateIcon />
      case currentIconEnums.crossCircles:
        return <CrossCirclesIcon />
      default:
        return <FlagIcon />
    }
  }

  return (
    <Wrapper aligment={aligment} width={width} className="flex-wrapper">
      <IconContainer themeColor={themeColor} iconLabel={icon}>
        {typeof icon === 'number' ? icon : <CurrentIcon icon={icon} />}
      </IconContainer>
      <ArticleText margins={gap} color={titleColor} className="semibold">
        {title}
      </ArticleText>
      <ArticleText fontWeight={400} color={'#636369'}>
        {description}
      </ArticleText>
    </Wrapper>
  )
}

export default FlexArticleItem
