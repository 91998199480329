import React from 'react'
import { Wrapper } from './styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'

const ForCustomer = ({
  title,
  description,
  link,
}: {
  title: string
  description: string
  link: string
}) => {
  return (
    <Link to={link}>
      <Wrapper>
        <div className="left-container">
          <h6>{title}</h6>
          <p>{description}</p>
        </div>
        <div className="right-container">
          <FontAwesomeIcon icon={faArrowRight} color="#06C68F" size="2x" />
        </div>
      </Wrapper>
    </Link>
  )
}

export default ForCustomer
