import styled from 'styled-components'

export const Wrapper = styled.div`
  label {
    font-family: 'Poppins';
    font-weight: bold;
  }

  input,
  textarea {
    resize: none;
    appearance: none;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #a7a7a7;
    font-family: 'Poppins';
    background: transparent;
  }

  input:focus {
    outline: none;
    appearance: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`

export const CheckBoxContainer = styled.div`
  margin-bottom: 32px;
  p {
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 16px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 9px;

    label {
      font-family: 'Poppins';
      color: #4c4c4c;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
      flex-wrap: wrap;
      justify-content: flex-start;
      & > div {
        width: 49%;
      }
    }
  }
`
