import { Link } from 'gatsby'
import styled from 'styled-components'
import { StyledText } from '..'

export const BlogPostContainer = styled(Link)`
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(50% - 30px);

  p {
    line-height: 1.5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .image-container .gatsby-image-wrapper {
    min-height: 300px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      border-radius: 10px;
    }
  }

  .read-more-button {
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }

  :hover {
    .read-more-button {
      gap: 15px;
    }
  }
`

export const BlogPostFeaturedContainer = styled(
  BlogPostContainer,
)`
  width: 100%;
  .image-container {
    position: relative;
    border-radius: 10px;
    img {
      border-radius: 10px;
    }

    .title-container {
      position: absolute;
      bottom: 40%;
      left: 20px;
      transform: translateY(50%);
      z-index: 2;
      padding: 10px;
      .title {
        color: white;
      }

      .read-more-button {
        color: white;
      }
    }

    ::after {
      content: '';
      border-radius: 10px;
      position: absolute;
      top: 0;
      z-index: 1;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 31, 23, 0.4);
    }
  }
`

export const StyledReadMore = styled(Link)`
  width: fit-content;
  .read-more-button {
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 5px;

    :hover {
      gap: 10px;
    }
  }
`

export const BlogPostRecentContainer = styled(Link)`
  :hover {
    .read-more-button {
      gap: 15px;
    }
  }
`

export const ReadTimeContainer = styled(StyledText)`
  display: flex !important;
  align-items: center;
  gap: 10px;
  margin: 0 !important;
`
