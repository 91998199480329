import React from 'react'
import { Content, Index, Wrapper } from './styled-components'

interface ItemProps {
  label: string
  description: string
  index: number
  active: boolean
}

const ModuleItem = ({ index, label, description, active }: ItemProps) => {
  return (
    <Wrapper>
      <Index active={active}>{index}</Index>
      <Content>
        <h6>{label}</h6>
        <p>{description}</p>
      </Content>
    </Wrapper>
  )
}

export default ModuleItem
